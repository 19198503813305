import HeaderImage from "../../images/header_bg_1.jpg";
import Header from "../../components/Header";
import StoryImage from "../../images/about1.jpg";
import VisionImage from "../../images/about2.jpg";
import MissionImage from "../../images/about3.jpg";
import "./about.css";

const About = () => {
  return (
    <>
      <Header title="About Us" image={HeaderImage}>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptate
        ratione, aut inventore enim laborum ex dolor perferendis iusto totam
        numquam.
      </Header>
      <section className="about__story">
        <div className="container about__story-container">
          <div className="about__section-image">
            <img src={StoryImage} alt="Our Story" />
          </div>
          <div className="about__section-content">
            <h1>Our Story</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
              pariatur, explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
            <p>
              explicabo beatae officia totam inventore modi laborum in sunt
              ratione. explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
            <p>
              consectetur adipisicing elit. Consequuntur, facilis.sunt ratione.
              explicabo beatae officia totam inventore modi laborum in sunt
              ratione
            </p>
          </div>
        </div>
      </section>

      <section className="about__Vision">
        <div className="container about__Vision-container">
          <div className="about__section-content">
            <h1>Our Vision</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
              pariatur, explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
            <p>
              explicabo beatae officia totam inventore modi laborum in sunt
              ratione. explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
          </div>
          <div className="about__section-image">
            <img src={VisionImage} alt="Our Vision" />
          </div>
        </div>
      </section>

      <section className="about__mission">
        <div className="container about__mission-container">
          <div className="about__section-image">
            <img src={MissionImage} alt="Our Mission" />
          </div>
          <div className="about__section-content">
            <h1>Our Mission</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure
              pariatur, explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
            <p>
              {" "}
              explicabo beatae officia totam inventore modi laborum in sunt
              ratione. explicabo beatae officia totam inventore modi laborum in
              sunt ratione. Lorem ipsum dolor sit amet, consectetur adipisicing
              elit. Iure pariatur, explicabo beatae officia totam inventore modi
              laborum in sunt ratione.
            </p>
            <p>
              consectetur adipisicing elit. Consequuntur, facilis.sunt ratione.
              explicabo beatae officia totam inventore modi laborum in sunt
              ratione
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
